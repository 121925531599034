import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBookingSeries, toggleBKFModal } from '@State/bkf/actions';
import { getClassStatus } from '@Utils/booking-util';
import { bkfModals } from '@State/bkf/constants';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { getRruleDescription } from './recurring-util';

const BookingSeriesModal = ({ routeParams }) => {
  const dispatch = useDispatch();
  const [series, setSeries] = useState(null);
  const { seriesId, recurrenceRule } = useSelector(state => state.bkf.get('bookingSeries'));

  useEffect(() => {
    dispatch(fetchBookingSeries(seriesId))
      .then((data) => setSeries(data));
  }, []);

  const onCloseForm = () => dispatch(toggleBKFModal(bkfModals.series, false));

  return (
    <ModalDialog
      focusTrapPaused
      title="Återkommande bokning"
      closeOnClickOutside={false}
      onClose={onCloseForm}
      contentSize="xl"
      alignTop
    >
      <div>
        <i className="fa fa-repeat mr1" />
        {getRruleDescription(recurrenceRule)}
      </div>
      <table className="table mt4">
        <thead>
          <tr>
            <th>Tid</th>
            <th>Platser</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {series?.bookings.map((booking) => {
            const { bookingId, startTime, booked, maxSlots, classStatus } = booking;
            const status = getClassStatus(booked, maxSlots, classStatus, series.published);
            return (
              <tr key={bookingId}>
                <td>{moment(startTime).format('LLLL')}</td>
                <td>{maxSlots}</td>
                <td>
                  <span className="color-dot" style={{ background: status.color }} />
                  {status.description}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </ModalDialog>
  );
};

export default BookingSeriesModal;
